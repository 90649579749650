<template>
  <div v-for="(item, index) in multipleFormData" :key="index">
    <div class="row mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-4">Form {{ index + 1 }}</h3>
        <div class="d-flex justify-content-between align-items-center gap-2">
          <button
            class="btn w-sm btn-primary waves-effect waves-light px-2"
            @click="copyNewForm(index)"
          >
            Copy
          </button>
          <button
            class="btn w-sm btn-success waves-effect waves-light px-2"
            @click="addnewForm()"
          >
            Add
          </button>
          <button
            @click="deleteForm(index)"
            v-if="index > 0"
            class="btn w-sm btn-danger waves-effect waves-light px-2"
          >
            Delete
          </button>
        </div>
      </div>
      <div class="col-lg-3">
        <label for="program">
          Program Name
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <div class="d-flex">
          <div class="d-flex align-items-center me-3">
            <input
              :checked="item.allCheck"
              @click="
                item.allCheck = true;
                item.error.program_error = '';
              "
              type="radio"
              :name="`choose-${index}`"
            />
            <label>All</label>
          </div>
          <div class="d-flex align-items-center">
            <input
              :checked="!item.allCheck"
              @click="
                item.allCheck = false;
                item.program = [];
              "
              type="radio"
              :name="`choose-${index}`"
            />
            <label>Select</label>
          </div>
        </div>

        <v-select
          :closeOnSelect="false"
          v-show="!item.allCheck"
          name="program"
          :clearable="false"
          id="program"
          :options="moduleList"
          label="name"
          :loading="!moduleList.length"
          multiple
          v-model="item.program"
          :selectable="(option) => selectedProgram(option, index)"
          @option:selected="handleChange('Program', index)"
          @option:deselected="handleChange('Program', index)"
          :class="{
            error: item.error?.program_error,
          }"
        />

        <div class="text-danger" v-if="item.error?.program_error">
          {{ item.error?.program_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="type">
          Type
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <v-select
          name="type"
          id="type"
          :options="getType"
          label="name"
          v-model="item.type"
          :selectable="(option) => option?.name != item?.type?.name"
          class="border-danger"
          :clearable="false"
          @option:selected="handleChange('Type', index)"
          @option:deselected="handleChange('Type', index)"
        />
        <!-- :selectable="(val)=> selectableType(val)" -->

        <div class="text-danger" v-if="item.error?.type_error">
          {{ item.error?.type_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3" v-if="item.type && item.type?.name != 'Orientation'">
      <div class="col-lg-3">
        <label for="remark">
          Level
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <v-select
          name="level"
          id="level"
          :options="getLevel"
          label="name"
          v-model="item.level"
          :selectable="(option) => option?.id != item?.level?.id"
          :clearable="false"
          @option:selected="handleChange('Level', index)"
          @option:deselected="handleChange('Level', index)"
        />

        <div class="text-danger" v-if="item.error?.level_error">
          {{ item.error?.level_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="remark">
          Percentage
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <input
          name="percentage"
          id="percentage"
          type="number"
          v-model="item.percentage"
          class="form-control"
          @input="(event) => handleChange('Percentage', index, event)"
        />
        <div class="text-danger" v-if="item.error?.percentage_error">
          {{ item.error?.percentage_error }}
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div class="row">
    <div class="col-md-6 mx-auto">
      <div class="mt-3 mb-3 ms-3 text-center">
        <router-link :to="{ name: 'assessment-criteria-percentage' }">
          <button type="button" class="btn w-sm btn-secondary me-5">
            Cancel
          </button>
        </router-link>

        <button
          :disabled="isLoading ? true : false"
          type="button"
          class="btn w-sm btn-success waves-effect waves-light px-3"
          @click="store()"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>

          {{ isLoading == true ? "Loading..." : "Save" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },

  components: {
    vSelect,
  },

  mixins: [validationErrors],

  data() {
    return {
      multipleFormData: [
        {
          program: [],
          allCheck: false,
          level: "",
          type: "",
          percentage: "",
          error: {
            program_error: "",
            level_error: "",
            type_error: "",
            percentage_error: "",
          },
        },
      ],
      isLoading: false,
      allowStore: true,
      // formData : ,
      // allCheck : true,
      moduleList: this.$store.getters["odoo/getAllPrograms"],
      getLevel: levels,
      getType: types,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  methods: {
    addnewForm() {
      this.multipleFormData.push({
        program: [],
        level: "",
        allCheck: false,
        type: "",
        percentage: "",
        error: {
          program_error: "",
          level_error: "",
          type_error: "",
          percentage_error: "",
        },
      });
      // console.log(this.multipleFormData, 'new ')
    },

    copyNewForm(index) {
      const copiedObject = JSON.parse(JSON.stringify(this.multipleFormData[index]));
      this.multipleFormData.push(copiedObject);
    },

    deleteForm(index) {
      this.multipleFormData.splice(index, 1);
    },

    selectedProgram(options, index) {
      return !this.multipleFormData[index]?.program?.length
        ? true
        : this.multipleFormData[index].program.find(
            (p) => p.id == options.id
          ) === undefined;
    },
    handleChange(type, index, event) {
      if (type == "Program") {
        if (
          this.multipleFormData[index]?.program?.length === 0 &&
          !this.multipleFormData[index]?.allCheck
        ) {
          this.multipleFormData[index].error.program_error =
            "Program is required.";
        } else {
          this.multipleFormData[index].error.program_error = "";
        }
      } else if (type == "Type") {
        this.multipleFormData[index].level = "";
        this.multipleFormData[index].error.level_error = "";
        if (!this.multipleFormData[index]?.type) {
          this.multipleFormData[index].error.type_error = "Type is required.";
        } else {
          this.multipleFormData[index].error.type_error = "";
        }
      } else if (type == "Level") {
        if (
          !this.multipleFormData[index]?.level &&
          this.multipleFormData[index]?.type?.name != "Orientation"
        ) {
          this.multipleFormData[index].error.level_error = "Level is required.";
        } else {
          this.multipleFormData[index].error.level_error = "";
        }
      } else if (type == "Percentage") {
        this.multipleFormData[index].percentage = Number(event?.target?.value);
        if (!this.multipleFormData[index]?.percentage) {
          this.multipleFormData[index].error.percentage_error =
            "Percentage is required.";
        } else {
          this.multipleFormData[index].error.percentage_error = "";
        }
      }
    },

    checkBeforeStore() {
      this.allowStore = true;
      this.multipleFormData.forEach((item) => {
        if (item.program.length == 0 && !item.allCheck)
          (item.error.program_error = "Program is required."),
            (this.allowStore = false);
        if (!item.type)
          (item.error.type_error = "Type is required."),
            (this.allowStore = false);
        if (!item.level && item.type?.name != "Orientation")
          (item.error.level_error = "Level is required."),
            (this.allowStore = false);
        if (!item.percentage)
          (item.error.percentage_error = "Percentage is required."),
            (this.allowStore = false);
      });
    },

    async store() {
      this.checkBeforeStore();
      if (this.allowStore) {
        // console.log('storing values are', this.multipleFormData)

        const formData = [];

        this.multipleFormData.forEach((item) => {
          this.isLoading = true;

          let data = {
            program_ids: item.allCheck
              ? this.moduleList.map((val) => val.id)
              : item.program.map((val) => val.id),
            type: item.type.name,
            level: item.level ? item.level.name : null,
            all_check: item.allCheck ? 1 : 0,
            percentage: item.percentage ?? 0,
          };

          formData.push(data);
        });

        await axios
          .post(
            `${this.baseUrl}admin/v2/assessment-criteria-percentage-multiple`,
            formData
          )

          .then(() => {
            this.$router.push({ name: "assessment-criteria-percentage" });
            this.toast.success("Successfully Created Criteria!");
          })
          .catch(() => this.toast.error("Something Went Wrong!"));

        this.isLoading = false;
      } else {
        console.log("Not allowed to store,");
      }
    },
  },
};
</script>
