<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="page-title">Add Assessment Criteria Percentage Form</h4>
          <div class="col-2">
            <v-select
              class=""
              name="formType"
              :clearable="false"
              id="formType"
              v-model="selectedFormType"
              :options="formTypes"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form v-if="selectedFormType == 'Single'">
              <!-- <div class="row mb-3">

                      <div class="col-lg-3">

                        <label for="name"> 
                            Name 
                            <span class="text-danger">*</span>
                        </label>

                      </div>

                      <div class="col-lg-6">
                        
                            <input 
                            name="name"
                            id="name"
                            type="text"  
                            class="form-control" 
                            :class="{
                              'p-invalid':this.v$.formData.name.$error,
                            }"
                            v-model="formData.name"/>

                            <v-errors
                              :vuelidateErrors="{
                                errors: this.v$.formData.name.$errors,
                                value: 'Name',
                              }"
                            ></v-errors>

                      </div>

                  </div> -->

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="program">
                    Program Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <input
                        type="radio"
                        name="choose"
                        :checked="allCheck"
                        @click="allCheck = true"
                      />
                      <label>All</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="choose"
                        :checked="!allCheck"
                        @click="
                          allCheck = false;
                          formData.program = [];
                        "
                      />
                      <label>Select</label>
                    </div>
                  </div>

                  <v-select
                    v-show="!allCheck"
                    name="program"
                    :clearable="false"
                    id="program"
                    :options="moduleList"
                    label="name"
                    :loading="!moduleList.length"
                    :class="{
                      'p-invalid': this.v$.formData.program.$error,
                    }"
                    v-model="formData.program"
                    :selectable="(data) => selectedProgram(data)"
                    multiple
                  />

                  <v-errors
                    v-show="!allCheck"
                    :vuelidateErrors="{
                      errors: this.v$.formData.program.$errors,
                      value: 'Program',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="type">
                    Type
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    name="type"
                    id="type"
                    :options="getType"
                    label="name"
                    class="border-danger"
                    :class="{
                      'p-invalid': this.v$.formData.type.$error,
                    }"
                    v-model="formData.type"
                    :clearable="false"
                  />
                  <!-- :selectable="(val)=> selectableType(val)" -->

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.type.$errors,
                      value: 'Type',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3" v-show="checkLevel()">
                <div class="col-lg-3">
                  <label for="remark">
                    Level
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    name="level"
                    id="level"
                    :options="getLevel"
                    label="name"
                    :class="{
                      'p-invalid': this.v$.formData.level.$error,
                    }"
                    v-model="formData.level"
                    :clearable="false"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.level.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Percentage
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="percentage"
                    id="percentage"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.percentage.$error,
                    }"
                    v-model="formData.percentage"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.percentage.$errors,
                      value: 'Percentage',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="mt-3 mb-3 ms-3 text-center">
                    <router-link
                      :to="{ name: 'assessment-criteria-percentage' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>

                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="store()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <MultipleCreate v-if="selectedFormType == 'Multiple'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import validationErrors from "../../../mixins/validationErrors";
import MultipleCreate from "./MultipleCreate.vue";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },

  components: {
    vSelect,
    MultipleCreate,
  },

  mixins: [validationErrors],

  data() {
    return {
      formData: {
        program: [],
        level: "",
        type: "",
        percentage: "",
      },
      allCheck: true,
      selectedFormType: "Single",
      moduleList: this.$store.getters["odoo/getAllPrograms"],
      formTypes: ["Single", "Multiple"],
      getLevel: levels,
      getType: types,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
      formData: {
        program: this.allCheck ? "" : { required },
        level: this.formData.type?.name == "Orientation" ? "" : { required },
        type: { required },
        percentage: { required },
      },
    };
  },

  methods: {
    async store() {
      if (this.allCheck) {
        this.formData.program = this.moduleList.map((val) => val.id);
      }

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        return;
      }
      this.isLoading = true;

      let data = {
        program_ids: this.allCheck
          ? this.formData.program
          : this.formData.program.map((val) => val.id),
        type: this.formData.type.name,
        level: this.checkLevel() ? this.formData.level.name : null,
        percentage: this.formData.percentage,
        all_check: this.allCheck ? 1 : 0,
      };
      console.log(data);
      await axios
        .post(`${this.baseUrl}admin/v2/assessment-criteria-percentage`, data)

        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "assessment-criteria-percentage" });
          this.toast.success("Successfully Created Criteria!");
        })
        .catch(() => this.toast.error("Something Went Wrong!"));
    },

    selectableType(val) {
      return !(val.id == this.formData?.type);
    },
    selectedProgram(option) {
      return !this.formData.program.length
        ? true
        : this.formData.program.find((p) => p.id == option.id) === undefined;
    },

    checkLevel() {
      if (this.formData.type !== "") {
        if (this.formData.type?.name !== "Orientation") {
          return true;
        }
      } else {
        return false;
      }
    },
  },

  async created() {},
};
</script>
